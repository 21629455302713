import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useAppContext } from "../../AppContext";

function Navbar() {
  const { user } = useAppContext();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  return (
    <div className={`navbar ${isMobileMenuOpen ? "mobileNavbarActive" : ""}`}>
      <div className="navbarItems">
        <div className="logoWrapper">
          <img src="assets/essiloss.png" alt="logo" />
        </div>
        <div className="menu">
          <ul>
            <HashLink smooth spy to="#introduction">
              <li className="menuItem">Önsöz</li>
            </HashLink>
            <HashLink smooth spy to="#achievements">
              <li className="menuItem">Başarılar</li>
            </HashLink>
            <HashLink smooth spy to="#faq">
              <li className="menuItem">S.S.S</li>
            </HashLink>
            <HashLink smooth spy to="#team">
              <li className="menuItem">Ekip</li>
            </HashLink>
            <HashLink smooth spy to="#contact">
              <li className="menuItem">İletişim</li>
            </HashLink>
          </ul>
        </div>
        <div className="btns">
          {user ? (
            <Link to="/dashboard/home" className="btn">
              Dashboard
            </Link>
          ) : (
            <>
              <Link to="/login" className="btn outlineBtn">
                Giriş Yap
              </Link>
              <Link to="/signUp" className="btn">
                Kayıt Ol
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="mobileMenuBtnDiv">
        <button
          className="mobileMenuBtn"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
      </div>
    </div>
  );
}

export default Navbar;
