import React from "react";
import "./OurTeam.css";
import TeamCard from "../TeamCard/TeamCard";

function OurTeam() {
  const admin = {
    name: "Essiloss",
    image: "/assets/essiloss-pp.jpeg",
    twitterLink: "https://x.com/Essiloss",
  };

  const teamList = [
    {
      name: "Ravionis",
      image: "/assets/ravi.png",
      twitterLink: "https://x.com/Ravionis",
    },
    {
      name: "Beatrixi",
      image: "/assets/beatrixi.jpeg",
      twitterLink: "https://x.com/beatrixiTrade",
    },
  ];
  return (
    <div className="ourTeam" id="team">
      <h1>Ekibimiz</h1>
      <div className="teamCards" data-aos="fade-right">
        <TeamCard teammate={admin} />
      </div>
      <div className="teamCards" data-aos="fade-left">
        {teamList.map((teammate, i) => (
          <TeamCard key={i} teammate={teammate} />
        ))}
      </div>
    </div>
  );
}

export default OurTeam;
