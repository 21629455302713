import React, { useEffect } from "react";
import "./Home.css";
import Introduction from "../../components/Introduction/Introduction";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import OurTeam from "../../components/OurTeam/OurTeam";
import Contact from "../../components/Contact/Contact";
import Faq from "../../components/Faq/Faq";
import Banner from "../../components/Banner/Banner";
import "aos/dist/aos.css";
import Aos from "aos";
import Achievements from "../../components/Achievements/Achievements";
import { useAppContext } from "../../AppContext";

function Home() {
  const { updateUserData } = useAppContext();

  useEffect(() => {
    Aos.init({
      disable: "mobile",
    });

    updateUserData();
  }, []);

  const essilossAchievements = [
    "/assets/essilossAchievements/payout1.png",
    "/assets/essilossAchievements/payout2.png",
    "/assets/essilossAchievements/payout3.jpeg",
    "/assets/essilossAchievements/payout4.jpeg",
    "/assets/essilossAchievements/payout5.jpeg",
    "/assets/essilossAchievements/payout6.jpeg",
    "/assets/essilossAchievements/payout7.jpeg",
    "/assets/essilossAchievements/payout8.jpeg",
    "/assets/essilossAchievements/payout9.jpeg",
    "/assets/essilossAchievements/payout10.png",
    "/assets/essilossAchievements/payout11.png",
    "/assets/essilossAchievements/payout12.png",
    "/assets/essilossAchievements/payout13.jpeg",
  ];

  const studentAchievements = [
    "/assets/studentAchievements/student1.jpeg",
    "/assets/studentAchievements/student2.jpeg",
    "/assets/studentAchievements/student13.jpeg",
    "/assets/studentAchievements/student3.jpeg",
    "/assets/studentAchievements/student4.jpeg",
    "/assets/studentAchievements/student5.jpeg",
    "/assets/studentAchievements/student14.jpeg",
    "/assets/studentAchievements/student6.jpeg",
    "/assets/studentAchievements/student7.jpeg",
    "/assets/studentAchievements/student8.jpeg",
    "/assets/studentAchievements/student15.jpeg",
    "/assets/studentAchievements/student9.jpeg",
    "/assets/studentAchievements/student10.jpeg",
    "/assets/studentAchievements/student11.jpeg",
    "/assets/studentAchievements/student12.jpeg",
  ];

  return (
    <>
      <Navbar />
      <div className="home">
        <Banner />
        <Introduction />
        <Achievements title="Başarılar" achievements={essilossAchievements} />
        <Achievements
          title="Öğrencilerimizin Başarıları"
          achievements={studentAchievements}
        />
        <Faq />
        <OurTeam />
        <Contact />
      </div>
      <Footer />
    </>
  );
}

export default Home;
